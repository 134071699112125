
import AddToClinicalSummaryButton from '@/components/visit/clinical-summary/AddToClinicalSummaryButton.vue';
import { ClinicalDocumentItemType, DocumentQuoteInput } from '@/models';
import Vue from 'vue';

export default Vue.extend({
    name: 'DocumentDetailViewBody',
    components: {
        AddToClinicalSummaryButton,
    },
    inject: {
        showAddToClinicalSummaryButton: { default: false },
    },
    props: {
        documentId: String,
        html: String,
    },
    data: () => ({
        ClinicalSummaryItemType: ClinicalDocumentItemType,
        showMenu: false,
        menuPositionX: undefined as number | undefined,
        menuPositionY: undefined as number | undefined,
        selectedText: undefined as string | undefined,
        startRange: undefined as number | undefined,
        endRange: undefined as number | undefined,
        isPlaintextDocument: true,
    }),
    computed: {
        quote() {
            if (this.selectedText) {
                return {
                    quote: this.selectedText,
                    startRange: this.startRange,
                    endRange: this.endRange,
                } as DocumentQuoteInput;
            }
            return undefined;
        },
    },
    watch: {
        html: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    const container = document.querySelector<HTMLElement>('.document-body');
                    const target = container?.querySelector<HTMLElement>('[class*="highlight"]');
                    if (target && container) {
                        this.$vuetify.goTo(target, { container: container });
                    } else {
                        container?.scrollTo(0, 0);
                    }
                });
                const element = document.querySelector('div.document_body meta[name="generator"][content*="Aspose.Words"]');
                if (element) {
                    this.isPlaintextDocument = false;
                }
            },
        },
    },
    methods: {
        openMenu(event: MouseEvent) {
            const selection = window.getSelection();
            const range = selection?.getRangeAt(0);
            if (selection?.toString().trim()) {
                this.menuPositionX = event.clientX;
                this.menuPositionY = event.clientY;
                this.selectedText = selection.toString();
                this.startRange = range?.startOffset;
                this.endRange = range?.endOffset;
                this.showMenu = true;
            }
        },
        closeMenu() {
            this.showMenu = false;
            this.selectedText = undefined;
        },
    },
});
